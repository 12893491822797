
$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    if ($(window).width() > 769) {
        $('.navbar .dropdown').hover(function () {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(250).show();

        }, function () {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(100).hide();

        });

        $('.navbar .dropdown > a').click(function () {
            location.href = this.href;
        });

    }

});


var flipBooks = {};
var pdfs = {};
function loadFlipbook(id){
    openbook = id;


    var bigImg = new Image();
    bigImg.onload = function() {
        bigImageWidth = this.width;
    }
    bigImg.src = flipBooksBig[openbook][0];

    //get image size to determine height width
    var count=0;
    var img = new Image();
    img.onload = function() {
        imageWidth=this.width*2;
        imageHeight=this.height;

        $('#canvas').fadeIn(800);
        var flipbook = $('#magazine');

        flipbook.turn({
            width: imageWidth,
            height: imageHeight,
            duration: 1000,
            acceleration: !isChrome(),
            gradients: true,
            autoCenter: true,
            elevation: 50,
            pages:1,
            when: {
                start: function(event, pageObject, corner) {

                },
                turning: function(event, page, view) {
                    var book = $(this),
                        currentPage = book.turn('page'),
                        pages = book.turn('pages');
                    disableControls(page);
                },
                turned: function(event, page, view) {
                    disableControls(page);
                    $(this).turn('center');
                    if (page==1) {
                        $(this).turn('peel', 'br');
                    }
                },
                missing: function (event, pages) {
                    // Add pages that aren't in the magazine
                    for (var i = 0; i < flipBooks[id].length; i++) {
                        addPage(i+1, $(this), flipBooks[id][i]);
                    }
                }
            }
        });

        //check if pdf exists
        if(pdfs[id]){
            $('.download').attr('href',pdfs[id]);
            $('.download').css('display','inline');
        }
        else{
            $('.download').css('display','none');
        }

        $('.magazine-viewport').zoom({
            flipbook: $('#magazine'),
            max: function() {
                return largeMagazineWidth()/$('#magazine').width();
            },
            when: {
                swipeLeft: function() {
                    $(this).zoom('flipbook').turn('next');
                },
                swipeRight: function() {
                    $(this).zoom('flipbook').turn('previous');
                },
                resize: function(event, scale, page, pageElement) {
                    if (scale==1)
                        loadSmallPage(page, pageElement);
                    else
                        loadLargePage(page, pageElement);
                },
                zoomIn: function () {
                    $('.thumbnails').hide();
                    $('.made').hide();
                    $('#magazine').removeClass('animated').addClass('zoom-in');
                    $('.zoom-icon').removeClass('zoom-icon-in').addClass('zoom-icon-out');
                    if (!window.escTip && !$.isTouch) {
                        escTip = true;
                        $('<div />', {'class': 'exit-message'}).
                        html('<div>Press ESC to exit</div>').
                        appendTo($('body')).
                        delay(2000).
                        animate({opacity:0}, 500, function() {
                            $(this).remove();
                        });
                    }
                },
                zoomOut: function () {
                    $('.exit-message').hide();
                    $('.thumbnails').fadeIn();
                    $('.made').fadeIn();
                    $('.zoom-icon').removeClass('zoom-icon-out').addClass('zoom-icon-in');
                    setTimeout(function(){
                        $('#magazine').addClass('animated').removeClass('zoom-in');
                        resizeViewport();
                    }, 0);
                }
            }
        });

        // Zoom event

        if ($.isTouch)
            $('.magazine-viewport').bind('zoom.doubleTap', zoomTo);
        else
            $('.magazine-viewport').bind('zoom.tap', zoomTo);


        // Using arrow keys to turn the page

        $(document).keydown(function(e){

            var previous = 37, next = 39, esc = 27;

            switch (e.keyCode) {
                case previous:

                    // left arrow
                    $('#magazine').turn('previous');
                    e.preventDefault();

                    break;
                case next:

                    //right arrow
                    $('#magazine').turn('next');
                    e.preventDefault();

                    break;
                case esc:

                    $('.magazine-viewport').zoom('zoomOut');
                    e.preventDefault();

                    break;
            }
        });


        $(window).resize(function() {
            resizeViewport();
        }).bind('orientationchange', function() {
            resizeViewport();
        });



        if ($.isTouch) {

            $('.thumbnails').
            addClass('thumbanils-touch').
            bind($.mouseEvents.move, function(event) {
                event.preventDefault();
            });

        } else {

            $('.thumbnails ul').mouseover(function() {

                $('.thumbnails').addClass('thumbnails-hover');

            }).mousedown(function() {

                return false;

            }).mouseout(function() {

                $('.thumbnails').removeClass('thumbnails-hover');

            });

        }

        // Events for the next button

        $('.next-button').bind($.mouseEvents.over, function() {

            $(this).addClass('next-button-hover');

        }).bind($.mouseEvents.out, function() {

            $(this).removeClass('next-button-hover');

        }).bind($.mouseEvents.down, function() {

            $(this).addClass('next-button-down');

        }).bind($.mouseEvents.up, function() {

            $(this).removeClass('next-button-down');

        }).click(function() {

            $('#magazine').turn('next');

        });

        // Events for the next button

        $('.previous-button').bind($.mouseEvents.over, function() {

            $(this).addClass('previous-button-hover');

        }).bind($.mouseEvents.out, function() {

            $(this).removeClass('previous-button-hover');

        }).bind($.mouseEvents.down, function() {

            $(this).addClass('previous-button-down');

        }).bind($.mouseEvents.up, function() {

            $(this).removeClass('previous-button-down');

        }).click(function() {

            $('#magazine').turn('previous');

        });


        resizeViewport();

        $('#magazine').addClass('animated');
        $('.zoom-icon').bind('mouseover', function() {

            if ($(this).hasClass('zoom-icon-in'))
                $(this).addClass('zoom-icon-in-hover');

            if ($(this).hasClass('zoom-icon-out'))
                $(this).addClass('zoom-icon-out-hover');

        }).bind('mouseout', function() {

            if ($(this).hasClass('zoom-icon-in'))
                $(this).removeClass('zoom-icon-in-hover');

            if ($(this).hasClass('zoom-icon-out'))
                $(this).removeClass('zoom-icon-out-hover');

        }).bind('click', function() {

            if ($(this).hasClass('zoom-icon-in'))
                $('.magazine-viewport').zoom('zoomIn');
            else if ($(this).hasClass('zoom-icon-out'))
                $('.magazine-viewport').zoom('zoomOut');

        });

        $('.magazine-viewport').bind('click', function(event) {
            if(event.target.id == 'magazine-viewport') {
                closeFlipbook();
                $('.magazine-viewport').unbind('click');
            }
        });
    };
    img.src = flipBooks[id][0];
}



function closeFlipbook(){
    if($('#magazine').turn('zoom') > 1){
        $("#magazine").bind("zooming", function(event,  newZoomValue, currentZoomValue) {
            $('#canvas').fadeOut('800',function(){
            $('#magazine').turn('destroy');
            openbook = null;
            });
        });
        $('.magazine-viewport').zoom('zoomOut', 150);

    }
    else {
        $('#canvas').fadeOut('800', function () {
            $('#magazine').turn('destroy');
            openbook = null;
        });
    }
}
